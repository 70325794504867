.contact-page {
    .intro-section {
        @include intro-section('/assets/landing/contact.jpg', polygon(100% 45%, 0 100%, 100% 100%), #fff, bottom, 0)
    }

    .about-section {
        background: #fff;

        .container {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        .title {
            font-size: 24px;
            line-height: 36px;
            color: #333;
        }

        .text {
            @extend %section-text;
            line-height: 28px;
            color: #333;
        }

        .section-title {
            @extend %section-title;
        }

        .slope {
            @include slope(polygon(100% 0, 0 80%, 0 0), #fff);
        }
    }

    .contact-details-section {
        background: #4899d4;

        .container {
            padding-top: 65px;
            padding-bottom: 50px;
        }

        .title {
            font-size: 16px;
            margin-bottom: 10px;
        }

        .text {
            font-size: 24px;
        }

        .line {
            color: black;
        }

        .phone-container {
            border: 0;
        }

        .slope {
            @include slope(polygon(100% 0, 0 0, 100% 80%), #4899d4);
        }
    }

    .company-details-section {
        background: #1e4a71;

        .container {
            padding-top: 65px;
            padding-bottom: 50px;
        }

        .logo {
            margin: 20px auto;
        }

        .company-address {
            line-height: 1.5;

            div {
                margin: 12px;
            }
        }

        .slope {
            @include slope(polygon(100% 0, 0 80%, 0 0), #1e4a71);
        }
    }

    .contact-form-section {
        background: #f2f4f7;

        .container {
            padding-top: 60px;
            padding-bottom: 50px;
        }

        .section-title {
            @extend %section-title;
        }

        .section-subtitle {
            @extend %section-subtitle;
        }

        .form {
            margin: 70px auto 0;
        }

        .form-group {
            margin-bottom: 28px;

            label {
                font-size: 16px;
                color: #333;
                margin-bottom: 5px;
            }

            ::placeholder {
                color: rgba(108, 117, 125, .7);
                font-size: 14;
            }
        }

        .form-check {
            font-size: 12px;
            line-height: 1.33;
            color: #666;
            margin-bottom: 30px;
        }

        .slope {
            @include slope(polygon(100% 0, 0 80%, 0 0), #f2f4f7);
        }
    }

    .order-section {
        @include order-section;
    }

    @include media-breakpoint-up(md) {
        .contact-form {
            .section-title {
                font-size: 36px;
            }
        }

        .contact-details-section {
            .phone-container {
                border-right: 1px solid white;
            }
        }

        .contact-details-section,
        .contact-form-section,
        .company-details-section {
            .container {
                padding-top: 110px;
                padding-bottom: 68px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .contact-details-section,
        .contact-form-section,
        .company-details-section {
            .container {
                padding-top: 135px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .contact-form-section,
        .company-details-section {
            .container {
                padding-top: 150px;
            }
        }
    }
}
