.news-article-page {
    .intro-section {
        @include intro-section('/assets/landing/news-example.jpg', polygon(100% 45%, 0 100%, 100% 100%), #fff, bottom, 0);
    }

    .news-article-section {
        background: #fff;

        .container {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        .section-title {
            @extend %intro-section-title;
            color: #333;
            margin-bottom: 30px;
            font-weight: 300;
            line-height: 1.5;
        }

        .article {
            font-size: 14px;
            color: #333;
            line-height: 22px;
        }

        .slope {
            @include slope(polygon(100% 0, 0 0, 100% 80%), #fff);
        }
    }

    .latest-news-section {
        background: #f2f4f7;

        .container {
            padding-top: 70px;
            padding-bottom: 50px;
        }

        .section-heading {
            padding: 0 0 30px;

            .section-title {
                @extend %section-title;
                font-weight: 300;
                color: #2e2e2e;
            }
        }

        .card-wrapper:last-child {
            padding-bottom: 30px;
        }

        .see-all-button {
            line-height: 2.25;
            padding: 5px 30px;
        }

        .slope-container {
            .slope {
                @include slope(polygon(100% 0, 0 80%, 0 0), #f2f4f7);
            }
        }
    }

    .order-section {
        @include order-section;
    }


    @include media-breakpoint-up(md) {
        .latest-news-section {
            .card-wrapper:last-child {
                padding-bottom: 0;
            }
        }

        .news-article-section {
            .article {
                max-width: 730px;
                font-size: 16px;
                line-height: 28px;
            }
        }

        .latest-news-section {
            .container {
                padding-top: 110px;
            }

            .section-heading {
                padding: 0 0 45px;

                .section-title {
                    color: #3a3a3a;
                    font-weight: 400;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .latest-news-section {
            .container {
                padding-top: 160px;
            }
        }
    }
}