.news-section {
    color: #333;
    position: relative;

    .news-heading {
        background: #ffca28;
    }

    .title {
        font-size: 25px;
        margin: 0;
        padding: 11px 0 16px;
    }

    .news-title-list {
        background: #ededed;

        .title {
            background: #ffca28;
        }

        .marquee {
            margin: 18px auto 22px;
            overflow: hidden;
            position: relative;

            .fade-effect-left {
                @include fade-effect(left);
            }

            .fade-effect-right {
                @include fade-effect(right);
            }

            &-text {
                font-size: 15px;
                display: inline-block;
                white-space: nowrap;
                animation: marquee 20s linear infinite;
                padding-left: 100%;

                a {
                    color: #333333;
                }

                &:hover {
                    animation-play-state: paused;
                }
            }
        }

        .link {
            color: #333;
            font-size: 13px;
            margin-bottom: 20px;

            &:before {
                content: 'pokaż wszystkie \00a0';
            }
        }
    }

    @keyframes marquee {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(-100%, 0);
        }
    }

    @include media-breakpoint-up(md) {
        .title {
            font-size: 36px;
        }

        .news-title-list {
            .marquee-text {
                font-size: 30px;
            }

            .title {
                padding: 20px 26px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .news-title-list {
            .title,
            .marquee-text {
                font-size: 22px;
            }

            .title {
                padding: 28px 0;
            }

            .link {
                margin: 0;
            }
        }
    }

    @include media-breakpoint-only(lg) {
        .news-title-list {
            .link {
                &:before {
                    display: none;
                }
            }
        }
    }
}