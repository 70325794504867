.card-wrapper {
    margin-bottom: 30px;

    .card {
        background: #fff;
        box-shadow: 0px 4px 9px 0 rgba(0, 0, 0, 0.1);
        border: 0;
        height: 100%;

        &-img-top {
            padding: 20px 20px 0;
        }

        .photo {
            .slope {
                @include slope(polygon(100% 45%, 0 100%, 100% 100%), #fff, bottom);
                height: 30px;
            }
        }

        &-title {
            font-size: 20px;
            line-height: 1.5;
            color: #333;
            margin-bottom: 20px;
        }

        &-text {
            font-size: 14px;
            line-height: 1.57;
            color: #999;
            margin-bottom: 20px;
        }

        .link {
            font-size: 16px;
        }
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 62px;

        .card {
            &-body {
                padding: 30px;
            }

            &-img-top {
                padding: 0;
            }

            &-text {
                font-size: 16px;
                line-height: 1.63;
            }
        }
    }
}
