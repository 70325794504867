#auth {
  background-color: #0e283b;

  //order

  .summary-text {
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #333333
  }
  .summary-additional-text{
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
  }
  .summary-price{
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
  }


  .order-summmary-price{
    height: 70px;
    background-color: #fff3cd;
  }

  .order-configuration-title {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 14px;

    &.office {
      color: #007bff;
      font-weight: bold;
    }

    &.collapse-action {
      font-size: 14px;
      font-weight: normal;
    }

    &.arrows {
      color: #007bff;
    }
  }

  .order-section-title{
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
  }


  // end order


  .greyed-text {
    font-size: 15px;
    color: #999999;
  }

  .wrapper {
    background-color: white;
  }

  .vertical-box {
    width: 661px;
  }

  .horizontal-box {
    width: 860px;
  }

  .label {
    color: #333333;
  }

  .title {
    margin: 30px 0 50px 0;
    font-size: 35px;
    font-weight: 300;
    line-height: 0.9;
    color: #191919;
  }

  .logo {
    margin-bottom: 29px;
  }

  .link {
    font-size: 15px;
    color: #3399cc;
  }

  .social-button {
    color: #ffffff;
    border-radius: $btn-border-radius;
    width: 105px;
    margin: 0 10px;
    font-size: 15px;

    a:visited {
      color: #37404e !important;
    }

    &.google {
      span:before {
        font-family: "Font Awesome 5 Brands";
        font-weight: 400;
        content: "\f1a0";
        margin-right: 7px;
      }

      background-color: #df4930;
    }

    &.fb {
      span:before {
        font-family: "Font Awesome 5 Brands";
        font-weight: 400;
        content: "\f39e";
        margin-right: 7px;
      }

      background-color: #3b5998;
    }
  }

  .send-reset-password-btn {
    height: 38px;
  }

  .user-agreement {
    font-size: 12px;
    color: #666666;
  }

  .company-data-box {
    border-radius: 5px;
    border: solid 1px #ced4da;
    background-color: #d5e3f3;
  }

}

@include media-breakpoint-up(md) {
  #auth {
    .title {
      font-size: 40px;
      margin-top: 71px;
    }
  }
}

.my-toast {
  background-color: #955;
  color: #fff;
}