* {
    font-family: 'Open Sans', sans-serif;
}

ul {
    list-style: none;
}

.roman-style-list li {
    list-style-type: lower-roman;
}

.latin-style-list li {
    list-style-type: lower-latin;
}

.disc-style-list li {
    list-style-type: disc;
}

/* hide arrows in input type number */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;}

input[type=number] {-moz-appearance: textfield;}

#video {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
#video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

$fa-font-path:        "../fonts/webfonts";

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import '~bootstrap/scss/bootstrap';
@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';
@import '~bootstrap-vue/src/index';

@import 'abstracts/global';
@import 'layout/global';
@import 'pages/global';

@import 'auth';
@import 'order';

@import 'account';
@import 'error';