.footer {
    background: #0e283b;
    text-align: center;

    .container {
        padding-top: 70px;
        padding-bottom: 53px;
    }

    .main-links {
        margin: 14px auto;
        font-size: 18px;
        line-height: 1.67;
    }

    .divider {
        opacity: .2;
        background-color: #ccc;
    }

    .faded-links {
        margin: 4px auto 30px;
        font-size: 14px;
        line-height: 1.29;
    }

    .link a,
    .social-link {
        color: rgba(255, 255, 255, .5);
    }

    .social-link {
        width: 28px;
        height: 28px;
        border-radius: $btn-border-radius;
        background-color: #254f6e;
        margin-left: 13px;
    }

    .copyright {
        font-size: 14px;
        line-height: 1.29;
        color: rgba(255, 255, 255, .3);
    }
}

@include media-breakpoint-up(md) {
    .footer {
        .container {
            padding-top: 130px;
            padding-bottom: 56px;
        }

        .main-links {
            font-size: 14px;
            margin: 48px auto 64px;
        }

        .link:after {
            content: "/";
            color: fff;
            padding: 0 20px;
        }

        .link:last-child:after {
            content: "";
            padding: 0;
        }

        .faded-links {
            margin: 0 auto 54px;

            .link:after {
                color: rgba(255, 255, 255, 0.5);
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .footer {
        .container {
            padding-bottom: 69px;
        }

        .main-links {
            font-size: 14px;
            margin: 0;
        }

        .divider {
            margin: 64px 0 45px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .footer {
        .container {
            padding-top: 150px;
            padding-bottom: 50px;
        }

        .main-links {
            font-size: 16px;
        }
    }
}
