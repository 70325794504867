.slick-prev:before,
.slick-next:before {
  color: #999;
  font-weight: 900;
  font-size: 25px;
  font-family: "Font Awesome 5 Free";
}

.slick-prev:before {
  content: "\f104";
}

.slick-next:before {
  content: "\f105";
}

.home-page {
    .carousel {
        &-caption {
            right: 10%;
            left: 10%;
            padding-bottom: 5px;

            .title {
                @extend %intro-section-title;
                margin-bottom: 15px;
            }

            .subtitle {
                @extend %intro-section-subtitle;
                margin-bottom: 15px;
                width: 90%;
            }
        }

        &-item {
            height: 350px !important;

            img {
                height: 230px;
            }
        }

        &-btn {
            border-radius: 10px;
            background-color: #fff;
            color: #2b5b7f;
            font-size: 20px;
            line-height: 1.7;
            padding: 0 24px;
        }

        &-control-next,
        &-control-prev {
            display: none;
        }

        &-indicators {
            display: none;
        }
    }

    .about-section {
        background-color: #161c2d;

        .container {
            padding-top: 35px;
            padding-bottom: 16px;
        }

        .title {
            font-size: 20px;
            line-height: 1.2;
            margin-bottom: 14px;
        }

        img {
            display: none;
        }

        .list {
            padding-left: 25px;

            &-item {
                margin-bottom: 24px;
                line-height: 1.33;

                &::before {
                    content: "•";
                    color: $text-blue;
                    width: 1em;
                    margin-left: -25px;
                    padding-right: 15px;
                }

                &-title {
                    font-size: 15px;
                    font-weight: 600;
                }

                &-text {
                    font-size: 15px;
                    margin-bottom: 0;
                    color: #7b7d83;
                }
            }
        }

        .slope {
            @include slope(polygon(100% 0, 0 0, 100% 80%), #161c2d);
        }
    }

.offer-section {
  background-color: #e8edf4;

  .container {
    padding-top: 65px;
  }

  .infographic {
    & {
        margin: 20px auto 50px;
      }

      @media (min-width:941px) and (max-width: 1280px) {
          max-width:75%;
      }
  }

  .offer-content {
    margin-bottom: 40px;
  }

  .section-title {
    @extend %section-title;
    color: #3a3a3a;

  }

  .title {
    font-size: 20px;
    color: $text-blue;
    line-height: 1.2;
    margin-bottom: 0;
  }

  .subtitle {
    font-size: 20px;
    color: #333;
    line-height: 1.2;
    margin-bottom: 12px;
  }

  .text {
    font-size: 15px;
    margin-bottom: 0;
    line-height: 1.33;
    color: #666;
  }

  .slope {
    @include slope(polygon(100% 0, 0 80%, 0 0), #e8edf4);
  }
}

.advantages-section {
  background-color: #fff;

  .container {
    padding-top: 65px;
  }

  .section-title {
    @extend %section-title;
    color: #3a3a3a;
    margin-bottom: 30px;
  }

  .advantages-content {
    margin-bottom: 68px;
  }

  .title {
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 400;
    margin: 16px 0;
  }

  .text {
    font-size: 15px;
    line-height: 1.33;
    color: rgba(46, 46, 46, 0.6);
    margin-bottom: 0;
  }

  .circular-image {
    width: 112px;
    height: 112px;
    background-color: #0072bc;
    border-radius: 100%;
  }

  .slope {
    @include slope(polygon(100% 0, 0 0, 100% 80%), #fff);
  }
}

.testimonials-section {
  background-color: #fff;

  .container {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .section-title {
    @extend %section-title;

    color: #3a3a3a;
  }

  .testimonials {
    margin-top: 27px;
  }

  .rating {
    color: #f5cb9a;
    font-size: 16px;
  }

  .text {
    font-size: 15px;
    color: #666;
    line-height: 1.33;
    margin: 11px 0 23px;
  }

  .circular-photo {
    width: 49px;
    height: 49px;
    margin-right: 18px;
  }

  .name {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.56;
    color: #2e2e2e;
    margin: 0;
  }

  .job {
    font-size: 14px;
    color: #999;
    margin-bottom: 0;
  }

  .slope {
    @include slope(polygon(100% 0, 0 80%, 0 0), #fff);
  }
}

.order-section {
  @include order-section(7px 30px, 7px 120px);
    }

@include media-breakpoint-up(md) {
  .navbar-brand {
    img {
      max-width: 100%;
    }
  }

  .carousel {
    &-caption {
      padding-bottom: 56px;

      .title {
        margin-bottom: 20px;
      }

      .subtitle {
        margin-bottom: 28px;
        width: 90%;
      }
    }

    &-item {
      height: 400px;

      img {
        height: 400px;
      }
    }

    &-btn {
      border-radius: 10px;
      font-size: 32px;
      padding: 0 34px;
    }

    &-control-next,
    &-control-prev {
      display: flex;
    }
  }

  .about-section {
    .container {
      padding-top: 60px;
      padding-bottom: 25px;
    }

    .title {
      font-size: 30px;
      margin-bottom: 18px;
    }

    .list-item {
      line-height: 1.75;
      margin-bottom: 50px;

      &-title {
        font-size: 18px;
      }

      &-text {
        font-size: 16px;
      }
    }
  }

  .offer-section {
    .container {
      padding-top: 100px;
      padding-bottom: 25px;
    }

    .infographic {
      margin: 58px auto 35px;
    }

    .section-title {
      font-size: 50px;
    }

    .title {
      font-size: 30px;
    }

    .subtitle {
      font-size: 30px;
    }

    .text {
      font-size: 16px;
      line-height: 1.75;
      margin-bottom: 30px;
    }
  }

  .advantages-section {
    .container {
      padding-top: 100px;
    }

    .section-title {
      font-size: 50px;
      color: #2e2e2e;
      margin-bottom: 52px;
    }

    .title {
      font-size: 26px;
      line-height: 1.08;
      margin: 46px 0 12px;
    }

    .text {
      font-size: 18px;
      line-height: 1.67;
    }
  }

  .testimonials-section {
    .container {
      padding-top: 71px;
      padding-bottom: 60px;
    }

    .section-title {
      font-size: 50px;
      color: #2e2e2e;
    }

    .testimonials {
      margin-top: 42px;
    }

    .text {
      font-size: 18px;
      line-height: 1.44;
      margin: 13px 0 47px;
    }
  }

  }

@include media-breakpoint-up(lg) {
  .carousel {
    &-caption {
      .title {
        margin-bottom: 12px;
      }

      .subtitle {
        width: 100%;
      }
    }

    &-item {
      height: 500px;

      img {
        height: 500px;
      }
    }

    &-indicators {
      display: flex;
    }
  }

  .about-section {
    .container {
      padding-top: 75px;
    }

    img {
      display: block;
    }

    .list-item {
      margin-bottom: 24px;
    }
  }

  .offer-section {
    .container {
      padding-top: 110px;
    }

    .section-title {
      font-size: 40px;
    }

    .infographic {
      margin: 18px auto 25px;
    }
  }

  .advantages-section {
    .container {
      padding-top: 110px;
    }

    .section-title {
      font-size: 40px;
      margin-bottom: 54px;
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      margin: 16px 0 10px;
    }

    .text {
      font-size: 14px;
      line-height: 2;
    }

    .circular-image {
      width: 92px;
      height: 92px;
    }
  }

  .testimonials-section {
    .container {
      padding-top: 61px;
    }

    .section-title {
      font-size: 40px;
    }

    .testimonials {
      margin-top: 62px;
    }

    .text {
      font-size: 16px;
      line-height: 1.63;
      margin: 12px 0 30px;
    }
  }

  }

@include media-breakpoint-up(xl) {
  .carousel {
    &-caption {
      padding-bottom: 70px;
    }
  }

  .about-section {
    .list-item {
      margin-bottom: 14px;
    }
  }

  .offer-section {
    .container {
      padding-top: 135px;
    }

    .infographic {
      margin: 10px auto 15px;
    }
  }

  .advantages-section {
    .container {
      padding-top: 135px;
    }

    .section-title {
      margin-bottom: 50px;
    }
  }

  .testimonials-section {
    .container {
      padding-top: 53px;
    }

    .testimonials {
      margin-top: 72px;
    }
  }}
}
