%section-title {
    font-size: 30px;
    line-height: 1.2;
}

%section-subtitle {
    font-size: 20px;
    color: #999;
    margin-top: 8px;
}

%intro-section-title {
    font-size: 20px;
    line-height: 1;
    font-weight: 600;

    @include media-breakpoint-up(md) {
        font-size: 50px;
    }
}

%intro-section-subtitle {
    font-size: 20px;
    line-height: 1.11;
    font-weight: 300;

    @include media-breakpoint-up(md) {
        font-size: 36px;
        line-height: 1.11;
    }
}

%section-text {
    font-size: 15px;

    @include media-breakpoint-up(md) {
        font-size: 16px;
    }
}

%document-section {
    .document-section {
        background: #fff;

        .container {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        ul, ol, p {
            font-size: 16px;
            color: #333;
            line-height: 28px;
        }

        .section-title {
            @extend %section-title;
        }

        .slope {
            @include slope(polygon(100% 0, 0 80%, 0 0), #fff);
        }

        @include media-breakpoint-up(md) {
            .section-title {
                font-size: 36px;
            }
        }
    }
}
