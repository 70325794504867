.calculator-section {

  background-color: #37a2f2;
  padding-top: 50px;

  .calculator-placeholder {
    padding-bottom: 50px;
    min-height: 530px;
  }

  .background {
    @include background-image("/assets/landing/calculator.jpg");
    background-size: auto;
    background-position: center bottom;
  }

  .new-cabinet-icon {
    width: 20px;
    height: 20px;
  }

  .header-text-lg {
    display: none;
  }

  .new-cabinet {
    border-radius: 5px;
    border-color: #254f6e;
    background-color: #254f6e;
    min-height: 50px;
    font-size: 16px;
  }

  .office-actions {
    font-size: 10px;
  }

  .section-title {
    margin-bottom: 31px;
  }

  .valuation-details-section {
    margin-bottom: 30px;
  }

  .service-price-section {
    margin-bottom: 100px;
    position: sticky;
  }

  .header {
    height: 54px;
    color: white;
    font-size: 20px;

    &.valuation-details {
      background-color: #254f6e;
    }

    &.price-summary {
      background-color: #254f6e;
    }
  }

  .research-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #FFCC00;
    color: black;
    padding: 20px 15px;
  }

  .research-header-price {
    font-size: 36px;
    font-weight: 700;
    line-height: 0.9;
  }

  .research-header-text {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 300;
  }

  .circle-number {
    width: 35px;
    height: 35px;
    border: 2px solid #fff;
  }

  .shadow {
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
    top: 92px
  }

  .valuation-section {
    margin: 2px 0 0 0;
    display: flex;
    align-content: center;
    justify-content: flex-start;
    background-color: #E4E4E4;
    padding: 5px 15px;
  }
  .valuation-section-label {
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
  }
  .valuation-section-value {
    font-size: 14px;
    font-weight: 300;
    line-height: 1;
    text-transform: uppercase;
    color: #666666;
    margin: 0 0 0 5px;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .valuation-content {
    font-weight: bold;
    font-size: 13px;
    padding: 2px 0 30px 0;
    color: #666666;
    background-color: white;
  }
  .valuation-section-dark {
    background-color: #254f6e;
    color: white;
  }
  .valuation-section-value-dark {
    color: white;
  }

  .valuation-new-benefits {
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .valuation-new-benefits-header {
    font-weight: 700;
    font-size: 16px;
    color: #254f6e;
    text-transform: uppercase;
    margin: 20px 0 5px 0;
  }
  .valuation-new-benefits-li {
    margin: 5px 0 0 0;
    font-size: 15px;
  }

  .content {
    font-weight: bold;
    font-size: 13px;
    padding-top: 31px;
    color: #666666;
    background-color: white;
    padding-bottom: 20px;
  }

  .sporals-number-input {
    width: 45px;
  }

  .order-button {
    line-height: 2;
    background: $chestnut-rose;
    border-radius: $btn-border-radius;
  }

  .dpd-logo {
    margin-left: 10px;
    height: 30px;
  }

  .inpost-logo {
    margin-left: 10px;
    height: 35px;
  }

  .text {
    line-height: 0.8;
  }

  .help-text {
    font-size: 11px;
    line-height: 0.8;
    color: #999999;
  }

  .price {
    font-size: 20px;
  }

  .spinner {
    font-size: 10px;
  }

  ul {
    list-style: disc;

    li {
      margin-top: 10px;
      font-weight: normal;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@include media-breakpoint-up(md) {
  .calculator-section {

    .new-cabinet-icon {
      width: 35px;
      height: 35px;
    }

    .new-cabinet {
      border-radius: 5px;
      border-color: #254f6e;
      background-color: #254f6e;
      min-height: 70px;
      font-size: 20px;
    }

    .office-actions {
      font-size: 14px;
    }

    .header-text-lg {
      display: block;
    }

    .header-text {
      display: none;
    }

    padding-top: 100px;

    .section-title {
      font-size: 40px;
      margin-bottom: 51px;
    }

    .content {
      font-size: 15px;
    }

    .dpd-logo {
      height: 40px;
    }

    .inpost-logo {
      height: 45px;
    }

    .help-text {
      font-weight: normal;
      font-size: 13px;
    }
  }
}


@include media-breakpoint-up(lg) {
  .calculator-section {

    .section-title {
      margin-bottom: 51px;
    }

    padding-top: 150px;

    .price {
      font-size: 30px;
    }
  }
}