.my-account-page {
    background:  #f2f4f7;

    .vue-component {
        .slope {
            @include slope(polygon(100% 0, 0 80%, 0 0), #f2f4f7);
        }
    }

    .order-section {
        @include order-section;
    }
}
