.sticky-top {
    transition: all, .3s;
}

.navbar {
    padding-top: 10px;
    padding-bottom: 10px;
}

.navbar-brand img {
    height: 49px;
    transition: all, .3s;
    max-width: 100%;
}

.shrink {
    .navbar {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .navbar-brand img {
        height: 39px;
    }
}

.navbar-expand-lg {
    .navbar-nav {
        .nav-link {
            font-size: 16px;
            color: $text-blue;
            padding: 14px;
        }

        .account-btn {
            border-radius: $btn-border-radius;
            background-color: #72b1e2;
            padding: 5px 33px;

            &:hover {
                background: #336699;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .navbar {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .navbar-brand img {
        height: 78px;
    }

    .shrink {
        .navbar {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .navbar-brand img {
            height: 60px;
        }
    }
}
