.offer-page {
    .intro-section {
        @include intro-section('/assets/landing/news.jpg', polygon(100% 45%, 0 100%, 100% 100%), #fff, bottom, 0);
    }

    .offer-section {
        .container {
            padding-top: 10px;
            padding-bottom: 60px;
        }


        .section {
            transition: background .2s, background-image .2s;

            .title {
                font-size: 20px;
                line-height: 0.9;
                color: #333;
                margin-top: 30px;
                margin-bottom: 18px;
            }

            .photo {
                height: 160px;
                filter: grayscale(1);
            }

            .circular-info {
                width: 130px;
                height: 130px;
                opacity: 0.8;
                background-color: #37a2f2;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .info {
                top: 45%;
                left: 50%;
                transform: translate(-50%, -45%);

                .info-number {
                    font-size: 57px;
                    line-height: .85;
                }

                .info-text {
                    font-size: 20px;
                }
            }

            .list {
                font-size: 14px;
                font-style: normal;
                line-height: 1.57;
                color: #333;

                li {
                    padding-bottom: 25px;
                }
            }

            &:hover {
                background: #f3f3f3;

                .photo {
                    filter: grayscale(0);
                }
            }
        }

        .problem-section {
            .photo {
                @include background-image("/assets/landing/offer-problem-horizontal-color.jpg", bottom right);
            }
        }

        .solution-section {
            .photo {
                @include background-image('/assets/landing/offer-solution-horizontal-color.jpg', top right);
            }
        }

        .benefits-section {
            .photo {
                @include background-image('/assets/landing/offer-benefits-horizontal-color.jpg');
            }
        }

        .slope {
            @include slope(polygon(100% 0, 0 80%, 0 0), #fff);
        }
    }

    .order-section {
        @include order-section;
    }

    @include media-breakpoint-up(md) {
        .offer-section {
            .container {
                padding-top: 28px;
            }

            .section {
                .title {
                    font-size: 40px;
                    color: #2e2e2e;
                    margin-top: 60px;
                    margin-bottom: 46px;
                }

                .photo {
                    height: 400px;
                }

                .circular-info {
                    width: 273px;
                    height: 273px;
                }

                .info {
                    .info-number {
                        font-size: 120px;
                    }

                    .info-text {
                        font-size: 44px;
                    }
                }

                .list {
                    font-size: 18px;
                    line-height: 1.67;
                    margin-bottom: 30px;

                    li {
                        padding-bottom: 30px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .offer-section {
            .title {
                margin-bottom: 50px;
            }

            .section {
                .photo {
                    height: 100%;
                    margin-top: 0;
                }
            }

            .problem-section {
                .photo {
                    @include background-image("/assets/landing/offer-problem-xl-color.jpg", bottom right);
                }
            }

            .solution-section {
                .photo {
                    @include background-image('/assets/landing/offer-solution-xl-color.jpg', top right);
                }
            }

            .benefits-section {
                .photo {
                    @include background-image('/assets/landing/offer-benefits-xl-color.jpg');
                }
            }
        }
    }
}
