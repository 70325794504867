
.icon-container-onboarding {
  position: absolute;
  right: 15px;
  top: 10px;
}

.icon-container-order {
  position: absolute;
  right: 15px;
  top: 10px;
}

@include media-breakpoint-up(md) {

  .icon-container-order {
    position: absolute;
    right: 15px;
    top: 42px;
  }
}

.icon-container {
  position: absolute;
  right: 15px;
  top: 48px;
}

.loader {
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: around 5.4s infinite;
}

@keyframes around {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.loader::after, .loader::before {
  content: "";
  background: white;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: #333 #333 transparent transparent;
  border-style: solid;
  border-radius: 20px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}

.loader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}

.modal .modal-export {
  max-width: 623px;
}
.address-text li {
  line-height: 1.67;
}

.cursor-pointer {
  cursor: pointer;
}
.checkbox-cursor-pointer {
  label {
    cursor: pointer;
  }
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
  color: rgba(0, 123, 255, 0.7);
}
.separator::before, .separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid rgba(0, 123, 255, 0.7);
}
.separator::before {
  margin-right: .25em;
}
.separator::after {
  margin-left: .25em;
}

.my-account-page {

  .sort-section {
    cursor: pointer;
  }

  .profile-container {
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }

  .test-filter-box {
    box-shadow: 0px 4px 9px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }

  .add-new-autoclave {
    font-size: 14px;
    width: 271px;
    height: 31px;
    border-radius: 5px;
    color: #007bff;
    border: 1px solid #007bff;;
    background-color: white;
  }

  .main-address {
    font-size: 14px;
    width: 240px;
    height: 31px;
    border-radius: 5px;
    border: 1px solid white;;
  }

  .autoclave-shadow {
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
  }

  .delete-autoclave {
    color: #007bff;
    font-size: 14px;
  }

  .office-data {
    background-color: #4899d4;
    color: #ffffff;
  }

  .autoclaves-list {
    .row {
      min-height: 60px;

    }

    .row:nth-child(odd) {
      background-color: #efefef;
    }

    .row:nth-child(even) {
      background-color: #ffffff;
    }
  }

  .profile-header {
    color: #ffffff;
    background-color: #161c2d;
    height:auto;
    font-size: 24px;
    font-weight: 600;
  }

  .documents-header {
    margin-top: 29px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    background-color: #161c2d;
  }

  .tests-header {
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    background-color: #161c2d;
  }

  .table-item-download {
    cursor: pointer;

    & a {
      color: #000000;
      text-decoration: underline;
    }

  }

  .my-account {
    font-size: 14px;
    color: #666666;
    margin: 20px 0;
  }

  .greeting-text {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .page-header {
    margin-top: 39px;
    margin-bottom: 27px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }

  .page-header-text {
    margin: 31px 0 26px 19px;
    font-size: 24px;
    font-weight: 600;
  }

  .menu-container {
    color: #333333;

    .menu-item {
      font-size: 16px;
      color: #333333;
      border-bottom: 1px solid #cccccc;

      &.logout {
        color: #2e6288;

        &:hover {
          color: #ff3333;
        }
      }
    }

    .menu-item-active {
      color: #0072bc;
      border-bottom: 1px solid #0072bc;
    }
  }

  //table

  .table-container {
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }

  .table-header {
    height: 60px;
    display: none;
    background-color: #161c2d;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
  }

  .table-item-no {
    font-size: 36px;
  }

  td {
    vertical-align: middle;
  }

  td, th {
    display: block;
  }

  tr:nth-child(even) {
    background-color: #efefef;
  }

  tbody > tr:hover {
    background-color: #fff3cd;
  }

  @include media-breakpoint-up(md) {

    .tests-header {
      margin-top: 29px;
    }

    .table-header {
      display: table-header-group;
    }

    .autoclave-container {
      &:nth-child(odd) {
        padding-left: 0;
      }

      &:nth-child(even) {
        padding-right: 0;
      }
    }

    .page-header {
      margin-top: 29px;
      margin-bottom: 47px;
    }

    .documents-header {
      color: #333333;
      background-color: #ffffff;
    }

    .tests-header {
      color: #333333;
      background-color: #ffffff;
    }

    .table-item-no {
      font-size: 18px;
    }

    .my-account {
      margin: 50px 0 20px 0;
    }

    td, th {
      display: table-cell;
    }

    .greeting-text {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 40px;
    }

    .menu-container {
      border-bottom: 1px solid #cccccc;
      color: #333333;

      .menu-item {
        font-size: 16px;
        color: #333333;
        border-bottom: none;

        &.logout {
          color: #2e6288;
        }
      }

      .menu-item-active {
        color: #0072bc;
        border-bottom: 1px solid #0072bc;
      }

    }

  }

}
