.privacy-policy-page {
    .intro-section {
        @include intro-section('/assets/landing/privacy-policy.jpg', polygon(100% 45%, 0 100%, 100% 100%), #fff, bottom, 0);
    }

    @extend %document-section;

    .order-section {
        @include order-section;
    }
}
