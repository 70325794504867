@mixin slope($clip-path-value, $background-color, $top-bottom-property: top, $top-bottom-property-value: -1px) {
    position: absolute;
    width: 100%;
    height: 9vw;
    #{$top-bottom-property}: $top-bottom-property-value;
    overflow: hidden;
    clip-path: $clip-path-value;
    -webkit-clip-path: $clip-path-value;
    background: $background-color;
}

@mixin background-image($image, $position: center) {
    background-image: url($image);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: $position;
}

@mixin intro-section($background-image, $slope...) {
    height: 230px;

    .title {
        @extend %intro-section-title;
    }

    .subtitle {
        @extend %intro-section-subtitle;
    }

    .slope {
        @include slope($slope...);
    }

    @include background-image($background-image);

    @include media-breakpoint-up(md) {
        height: 420px;
    }
}

@mixin order-section($padding-sm: 5px 30px, $padding-md: 5px 80px) {
    background-color: #4899d4;

    .container {
        padding-top: 65px;
        padding-bottom: 50px;
    }

    .section-title {
        @extend %section-title;
        margin-bottom: 30px;
    }

    .order-button {
        line-height: 2.25;
        background: $bright-red;
        border-radius: $btn-border-radius;
        padding: $padding-sm;
        width: 100%;
    }

    .slope {
        @include slope(polygon(100% 0, 0 0, 100% 80%), #4899d4);
    }

    @include media-breakpoint-up(md) {
        .container {
            padding-top: 100px;
            padding-bottom: 68px;
        }

        .section-title {
            font-size: 36px;
            margin-bottom: 45px;
        }

        .order-button {
            padding: $padding-md;
            width: auto;
        }
    }

    @include media-breakpoint-up(lg) {
        .container {
            padding-top: 135px;
        }
    }

    @include media-breakpoint-up(xl) {
        .container {
            padding-top: 150px;
        }
    }
}

@mixin fade-effect($left-right-property) {
    &:after {
        content: "";
        position: absolute;
        top: 0;
        #{$left-right-property}: 0;
        height: 100%;
        width: 20px;
        background-image: linear-gradient(to $left-right-property, rgba(52, 73, 94, 0), #ededed);
    }

    @include media-breakpoint-up(md) {
        &:after {
            width: 30px;
        }
    }
}