.know-how-page {
    .intro-section {
        @include intro-section('/assets/landing/know-how.jpg', polygon(100% 45%, 0 100%, 100% 100%), #fff, bottom, 0);
    }

    .FAQ-section {
        .section-title {
            @extend %section-title;
            font-weight: 600;
            margin-bottom: 0;
        }

        .container {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        .card {
            border: 0;

            &-header {
                background-color: #fff;
                padding: 16px 0;
            }

            &-body {
                @extend %section-text;
                color: #999;
                line-height: 1.63;
                padding-left: 0;
                padding-right: 0;
            }
        }

        .accordion>.card:not(:last-of-type) {
            border-bottom: 1px solid rgba(0, 0, 0, .125);
        }

        .accordion-btn {
            @extend %section-text;
            line-height: 2.25;
            color: #2e2e2e;
            padding: 0;

            &:focus {
                box-shadow: none;
            }

            &::after {
                content: "+";
            }

            &[aria-expanded="true"] {
                color: #377dff;

                &:after {
                    content: "-";
                }
            }
        }

        .slope {
            @include slope(polygon(100% 0, 0 0, 100% 80%), #fff);
        }
    }

    .contact-section {
        background: #f2f4f7;

        .container {
            padding-top: 65px;
            padding-bottom: 50px;
        }

        .section-title {
            @extend %section-title;
            color: #2e2e2e;
        }

        .section-subtitle {
            @extend %section-subtitle;
        }

        .btns {
            margin-top: 75px;
        }

        .btn {
            width: 271px;
            margin-bottom: 15px;
            line-height: 2.25;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        .slope {
            @include slope(polygon(100% 0, 0 80%, 0 0), #f2f4f7);
        }
    }

    .order-section {
        @include order-section;
    }

    @include media-breakpoint-up(md) {
        .contact-section {
            .section-title {
                font-size: 36px;
            }

            .container {
                padding-top: 110px;
                padding-bottom: 68px;
            }

            .btn {
                width: 352px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .FAQ-section {
            .container {
                max-width: 730px;

            }
        }

        .contact-section {
            .container {
                padding-top: 135px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .contact-section {
            .container {
                padding-top: 150px;
            }
        }
    }
}
