.news-page {
    .intro-section {
        @include intro-section('/assets/landing/news.jpg', polygon(100% 45%, 0 100%, 100% 100%), #f2f4f7, bottom, 0);
    }

    .news-section {
        background: #f2f4f7;

        .container {
            padding-top: 30px;
            padding-bottom: 50px;
        }

        .slope-container {
            .slope {
                @include slope(polygon(100% 0, 0 80%, 0 0), #f2f4f7);
            }
        }
    }

    .order-section {
        @include order-section;
    }

    @include media-breakpoint-up(md) {
        .news-section {
            .container {
                padding-top: 60px;
            }
        }
    }
}
